import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';


const Header = lazy(() => import("./../../common/header"));
const Footer = lazy(() => import("./../../common/footer"));
const WhoweareBanner = lazy(() => import("./../who-we-are/banner-component"));
const HomeEnquiryform = lazy(() => import("./../../home-component/home-enquiryform"));
const WhoweareAbout = lazy(() => import("./../who-we-are/about-component"));
const WhoweareMission = lazy(() => import("./../who-we-are/mission-component"));
const WhoweareProduct = lazy(() => import("./../who-we-are/product-component"));
const WhoweareFeature = lazy(() => import("./../who-we-are/feature-component"));
const HomeVisitOffice = lazy(() => import("./../../home-component/home-visitoffice"));


class AboutWhowearePage extends PureComponent {


  constructor(props) {
    super(props);
    this.state = {



      aboutwhoweare_meta_title: "",
      aboutwhoweare_meta_description: "",




      errorsxx: {},

      data: {

      },


    };

    this.inputRef = React.createRef();




  }








  componentDidMount() {


    const windowUrl = window.location.href;

    let dataxx = windowUrl.split("#");

    if (dataxx[1] == "mission") {
      scrollToComponent(this.Blue, { offset: 0, align: 'middle', duration: 500, ease: 'inCirc' });

    }


    const self = this;
    axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
      .then(res => {

        self.setState({

          aboutwhoweare_meta_title: res.data[0].aboutwhoweare_meta_title,
          aboutwhoweare_meta_description: res.data[0].aboutwhoweare_meta_description,

        });




      });
  }


  render() {

    let urlxx = window.location.href;
    return (
      <Fragment>
        <Suspense fallback={<p></p>}>
          <Header />
          <Helmet>

            {urlxx == 'https://www.marquispoint.com/about#mission' ?
              <title><b>Mission |</b>  <b>Vission | </b>  Luxury Real Estate Developer in Arjan, Dubai</title>
              : <title>{this.state.aboutwhoweare_meta_title}</title>
            }
            <meta name="description" content={this.state.aboutwhoweare_meta_description} />

            {
              urlxx == 'https://www.marquispoint.com/about' ?
                <meta name="keywords" content="dubai real estate,dubai property,houses for sale in dubai,propertyfinder dubai,real estate companies in dubai,buy property in dubai" />

                : urlxx == 'https://www.marquispoint.com/whoweare' ?
                  <meta name="keywords" content="properties for sale in dubai, real estate agents in dubai,townhouses for sale in dubai,houses dubai rent,buying a apartment in dubai,villa sales dubai,rental dubai villa" />

                  : urlxx == 'https://www.marquispoint.com/about#mission' ?
                    <meta name="keywords" content="apartments near me,apartments for rent, apartments for rent near me,best apartments near me, real estate, propety developers in dubai, building better homes, luxurious life, vision and mission of luxury real estate developers." />


                    :
                    ''
            }
            <link rel="canonical" href='/whoweare' />
          </Helmet>
          <WhoweareBanner />
          <HomeEnquiryform />
          <WhoweareAbout />
          <WhoweareMission />

          <WhoweareProduct ref={(section) => { this.Blue = section; }} />
          <WhoweareFeature />
          <HomeVisitOffice />
          <Footer />

        </Suspense>
      </Fragment >
    )
  }
}


export default AboutWhowearePage