import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import 'bootstrap-4-react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';

const Header = lazy(() => import("./../../common/header"));
const Footer = lazy(() => import("./../../common/footer"));
// const BlogInnerBanner = lazy(() => import("./blog-inner-banner"));
const BlogInner = lazy(() => import("./blog-inner"));
const BlogSwiper = lazy(() => import("../blog-swiper"));
const HomeVisitOffice = lazy(() => import("../../home-component/home-visitoffice"));





class BlogInnerPage extends PureComponent {



    constructor(props) {
        super(props);
        this.state = {

            selectedFiles: undefined,
            currentFile: undefined,
            section_title: "",
            section_description: "",
            section_background_image: "",
            section_title2: "",
            section_description2: "",

            errors: {},
            imageURL: "",

            formValues: [{ id: "", slug: "", blog_inner_meta_title: "", blog_inner_meta_description: "", }],
           


        };


    }


    async componentDidMount() {

        this.fetch_dataxx();

        const windowUrl = window.location.href;

        let dataxxyyzz = windowUrl.split("blog-inner/");

        var self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
        .then(res => {

            var children = res.data[0].child;

            var formValues = [...self.state.formValues];
            


            formValues.splice(0, 1);
            

            children.forEach(element => {

                console.log(element);

                
                var dataxx = [];

                if (element.type == "blog" && element.slug==dataxxyyzz[1]) {

                    dataxx.type = element.type;
                    dataxx.blog_inner_meta_title = element.meta_title;
                    dataxx.blog_inner_meta_description = element.meta_description;
                    dataxx.slug = element.slug;


                    

                    formValues.push(dataxx);
                    
                }
               

            });

            
            

            self.setState({ formValues });

            console.log(self.state.formValues);
        });
    }


    fetch_dataxx() {

        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("blog-inner/");

        axios.post(apiUrl.apiUrl + '/blogs/edit_blogs_post/' + dataxx[1])
            .then(res =>

                this.setState({
                    section_title: res.data[0].section_title,
                    section_description: res.data[0].section_description,
                    post_id: res.data[0].id,
                    section_title2: res.data[0].section_title2,
                    section_description2: res.data[0].section_description2,
                    imageURL: apiUrl.apiUrl + "/mvd/blogspostsection/" + res.data[0].section_background_image,
                })

            );


        console.log(this.state);

    }

    render() {

        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("blog-inner/");

        return (<Fragment>
            <Suspense fallback={<p></p>}>
            <Header />
            {/* <BlogInnerBanner /> */}
            <Helmet>
            <title>{this.state.formValues[0].blog_inner_meta_title}</title>
                    <meta name="description" content={this.state.formValues[0].blog_inner_meta_description} />

                <link rel="canonical" href={'/blog-inner/' + dataxx[1]} />
            </Helmet>
            <BlogInner />
            <BlogSwiper />
            <HomeVisitOffice />
            <Footer />
            </Suspense>
        </Fragment>)
    }



}

export default BlogInnerPage