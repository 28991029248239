import React, { PureComponent, Fragment } from 'react'; 
import { Container, Col, Row, Button } from 'bootstrap-4-react';

class ThankyouBanner extends PureComponent {
 
    render() { 
        return ( 
            <Fragment>   
                <section className="bannerSec privacySecBanner" id="thankyoubanner-sec">
                <div className='overlay'></div>
                  <Container fluid className="bannerHeight">
                            <Row className="row bannerRow">
                                <Col md={6} className="bannerDiv">
                                    <h1 className='canelaBold bannerHeading'>Thank you for your enquiry.</h1>
                                    <p>One of our representatives will get in touch with your shortly.</p>
                                    <a href="/home" className="btn  btn goldenborderWhiteBtn pr-3 pl-3 pr-md-5 pl-md-5">Go to Home</a>
                                </Col>
                            </Row>
                  </Container>
                </section>
          </Fragment> 
        )
      }
}


export default ThankyouBanner