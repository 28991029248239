import React, { PureComponent, Fragment } from 'react';
import 'bootstrap-4-react';
import AboutWhowearePage from './../about-component/who-we-are/whoweare-page';

class AboutPage extends PureComponent {
  render() {
    return (
      <Fragment>


        <AboutWhowearePage />
      </Fragment>
    )
  }
}


export default AboutPage