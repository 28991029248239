import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../apiUrl';

const Header = lazy(() => import("./../common/header"));
const Footer = lazy(() => import("./../common/footer"));
const LegalBanner = lazy(() => import("./legaldocument-banner"));
const LegalDescription = lazy(() => import("./legaldocument-decription"));
const HomeVisitOffice = lazy(() => import("./../../components/home-component/home-visitoffice"));



class LegalDocuemtPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {



            legal_meta_title: "",
            legal_meta_description: "",



            errorsxx: {},

            data: {

            },


        };

        this.inputRef = React.createRef();




    }


    componentDidMount() {


        const self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
            .then(res => {

                self.setState({

                    legal_meta_title: res.data[0].legal_meta_title,
                    legal_meta_description: res.data[0].legal_meta_description,

                });




            });
    }


    render() {
        return (
            <Fragment>
                <Suspense fallback={<p></p>}>
                <Header />
                <Helmet>
                <title>{this.state.legal_meta_title}</title>
                    <meta name="description" content={this.state.legal_meta_description} />
                    <link rel="canonical" href='/legal-documents' />
                </Helmet>
                <LegalBanner />
                <LegalDescription />
                <HomeVisitOffice />
                <Footer />
                </Suspense>
            </Fragment>
        )
    }
}
export default LegalDocuemtPage