 
import React, { PureComponent, Fragment } from 'react';
import Header from './../common/header'; 
import Footer from './../common/footer'; 
 import NewsBanner from './news-banner'; 
 import LatestNews from './latest-news';
 import HomeVisitOffice from './../home-component/home-visitoffice';
 import { Helmet } from 'react-helmet';
class NewsPage extends PureComponent {
    render(){
        return(
            <Fragment>
            <Header />  
                <Helmet>
                    <title>Marquis Point</title>
                    <link rel="canonical" href='/news' />
                </Helmet>
                <NewsBanner /> 
                <LatestNews />
                <HomeVisitOffice />
            <Footer />
            </Fragment>
        )
    }
}
 export default NewsPage