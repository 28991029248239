import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';

const Header = lazy(() => import("./../../common/header"));
const Footer = lazy(() => import("./../../common/footer"));
const EventsDescription = lazy(() => import("./events-decriptions"));
const HomeVisitOffice = lazy(() => import("./../../home-component/home-visitoffice"));


class EventsInnerPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            selectedFiles: undefined,
            currentFile: undefined,
            section_title: "",
            section_description: "",
            section_background_image: "",
            section_title2: "",
            section_description2: "",

            errors: {},
            imageURL: "",

            formValues4: [{ id: "", slug: "", events_inner_meta_title: "", events_inner_meta_description: "", }],
            errors4: [{ slug: "", events_inner_meta_title: "", events_inner_meta_description: "", }],
        };


    }


    async componentDidMount() {

        this.fetch_dataxx();

        const windowUrl = window.location.href;

        let dataxxyyzz = windowUrl.split("events-inner/");

        var self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
            .then(res => {

                var children = res.data[0].child;

                var formValues4 = [...self.state.formValues4];



                formValues4.splice(0, 1);



                children.forEach(element => {

                    console.log(element.type);


                    var dataxx = [];

                    if (element.type == "events" && element.slug == dataxxyyzz[1]) {

                        dataxx.type = element.type;
                        dataxx.events_inner_meta_title = element.meta_title;
                        dataxx.events_inner_meta_description = element.meta_description;
                        dataxx.slug = element.slug;




                        formValues4.push(dataxx);

                    }


                });




                self.setState({ formValues4 });


            });
    }


    fetch_dataxx() {

        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("events-inner/");

        axios.post(apiUrl.apiUrl + '/events/edit_events_post/' + dataxx[1])
            .then(res =>

                this.setState({
                    section_title: res.data[0].section_title,
                    section_description: res.data[0].section_description,
                    post_id: res.data[0].id,
                    section_title2: res.data[0].section_title2,
                    section_description2: res.data[0].section_description2,
                    imageURL: apiUrl.apiUrl + "/mvd/eventspostsection/" + res.data[0].section_background_image,
                })

            );


        console.log(this.state);

    }
    render() {
        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("events-inner/");
        return (
            <Fragment>
                <Suspense fallback={<p></p>}>
                <Header />
                <Helmet>
                    <title>{this.state.formValues4[0].events_inner_meta_title}</title>
                    <meta name="description" content={this.state.formValues4[0].events_inner_meta_description} />

                    <link rel="canonical" href={'/events-inner/' + dataxx[1]} />
                </Helmet>
                <EventsDescription />
                <HomeVisitOffice />
                <Footer />
                </Suspense>
            </Fragment>
        )
    }
}
export default EventsInnerPage