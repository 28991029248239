import React, { PureComponent, Fragment } from 'react'; 
// Router Links
import { BrowserRouter, Routes, Route } from "react-router-dom"; 

import ScrollToTop from './scrolltotop';
import './../styles/styles.scss';
import 'bootstrap-4-react';

// AOS ANIMATION
import AOS from 'aos';
import 'aos/dist/aos.css';



// Pages Links Here
 
import HomePage from './pages/home';
import AboutPage from './pages/about-us';
import OurteamPage from './about-component/our-team/ourteam-page';
import AboutWhowearePage from './about-component/who-we-are/whoweare-page';
import InvestPage from './about-component/invest/invest-page';
import CareerPage from './about-component/career/career-page';
import CareerInnerPage from './about-component/career/career-inner/career-inner-page';
import CommuntiesPage from './communties-component/communties-page';
import CommuntiesInnerPage from './communties-component/communties-inner/communties-inner-page';
import PropertiesPage from './properties-component/properties-page';
import PropertiesInnerPage from './properties-component/properties-inner/properties-inner-page';
import NewsPage from './news-component/news-page';
import NewsInnerPage from './news-component/news-inner/news-inner-page';
import EventsPage from './events-component/events-page';
import EventsInnerPage from './events-component/events-inner/events-inner-page';
import ContactPage from './contact-component/contact-page';
import AgentPage from './become-agent-component/become-agent-page';
import PrivacyPage from './privacy-component/privacy-page';
import TermsPage from './terms-component/terms-page';
import LegalDocuemtPage from './legaldocument-component/legaldocument-page';
import FaqPage from './faq-component/faq-page';
import PageBlog from './blog-component/blog-page';
import BlogInnerPage from './blog-component/blog-inner/blog-inner-page';
import ThankYouPage from './thank-you-component/thank-you-page';

import NotfoundPage from './pages/404-page'; 
 
class App extends PureComponent {
 
    render() { 
      AOS.init({
        duration : '1000',   
        // once: true,
        mobile:false
      });
      AOS.refresh();


     
     
        return (
          <Fragment>  
            <BrowserRouter  /* basename='/marquis-point' */>
              <ScrollToTop />  
                <Routes>
                    <Route path="/">
                    <Route index element={<HomePage />} />
                    <Route exact path="home" element={<HomePage />} />
                    <Route exact path="about" element={<AboutPage />} />
                    <Route exact path="whoweare" element={<AboutWhowearePage />} />
                    <Route exact path="ourteam" element={<OurteamPage />} />
                    <Route exact path="invest" element={<InvestPage />} />
                    <Route exact path="careers" element={<CareerPage />} /> 
                    <Route exact path="/career-inner/:id" element={<CareerInnerPage />} /> 
                    <Route exact path="/communities" element={<CommuntiesPage />} /> 
                    <Route exact path="/communties-inner/:id" element={<CommuntiesInnerPage />} /> 
                    <Route exact path="properties" element={<PropertiesPage />} /> 
                    <Route exact={false} path="/properties-inner/:id" element={<PropertiesInnerPage />}  /> 
                    <Route exact path="news" element={<NewsPage />} /> 
                    <Route exact path="/news-inner/:id" element={<NewsInnerPage />} /> 
                    <Route exact path="events" element={<EventsPage />} /> 
                    <Route exact path="/events-inner/:id" element={<EventsInnerPage />} /> 
                    <Route exact path="contact" element={<ContactPage />} /> 
                    <Route exact path="become-an-agent" element={<AgentPage />} /> 
                    <Route exact path="privacy-policy" element={<PrivacyPage />} />
                    <Route exact path="terms-conditions" element={<TermsPage />} />  
                    <Route exact path="legal-documents" element={<LegalDocuemtPage />} />  
                    <Route exact path="blog" element={<PageBlog />} /> 
                    <Route exact path="/blog-inner/:id" element={<BlogInnerPage />} />  
                    <Route exact path="faq" element={<FaqPage />} />  
                    <Route exact path="*" element={<NotfoundPage />} />  
                    <Route exact path="/thank-you" element={<ThankYouPage />} />
                    </Route>
                   
                </Routes>
            </BrowserRouter> 
          </Fragment>
        )
      }
}


export default App

