import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';

const Header = lazy(() => import("./../../common/header")); 
const Footer = lazy(() => import("./../../common/footer")); 
const CareerBanner = lazy(() => import("./../../about-component/career/career-banner"));
// const HomeEnquiryform from = lazy(() => import("./../../home-component/home-enquiryform")); 
const CareerLinks = lazy(() => import("./../../about-component/career/career-links"));
const CareerForm = lazy(() => import("./../../about-component/career/career-form"));
const HomeVisitOffice = lazy(() => import("./../../home-component/home-visitoffice"));




class CareerPage extends PureComponent {


    constructor(props) {
        super(props);
        this.state = {


           
            careers_meta_title: "",
            careers_meta_description: "",

          

            errorsxx: {},

            data: {

            },

          
        };

        this.inputRef = React.createRef();


      

    }

    componentDidMount() {


        const self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
        .then(res => {

            self.setState({

                careers_meta_title: res.data[0].careers_meta_title,
                careers_meta_description: res.data[0].careers_meta_description,

            });




        });
    }



    render(){
        return(
            <Fragment>
                <Suspense fallback={<p></p>}>
            <Header />  
                <Helmet>
                    <title>{this.state.careers_meta_title}</title>
                    <meta name="description" content={this.state.careers_meta_description} />
                    <link rel="canonical" href='/careers' />
                </Helmet>
              <CareerBanner />
              {/* <HomeEnquiryform/> */}
              <CareerLinks /> 
              <CareerForm />
              <HomeVisitOffice />
            <Footer />
            </Suspense>
            </Fragment>
        )
    }
}
 export default CareerPage