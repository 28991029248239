import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import axios from 'axios';
import * as apiUrl from '../../apiUrl';

class NewsBanner extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

            selectedFiles: undefined,
            currentFile: undefined,
            section_title: "",
            section_description: "",

            errors: {},
            imageURL: "",
            data: {

            },





        };


    }


    componentDidMount() {

        axios.post(apiUrl.apiUrl + '/news/edit_news_page_top_section/1')
            .then(res => {


                

                this.setState({
                    section_title: res.data[0].section_title,
                    section_description: res.data[0].section_description,

                    imageURL: apiUrl.apiUrl + "/mvd/newstopsection/" + res.data[0].section_background_image,
                })

            }
            );




    }



    render() {


        let section_title = this.state.section_title;



        let section_description = this.state.section_description;



        return (
            <Fragment>

                <Container fluid className="bannerSec newsSecBanner" style={{ backgroundImage: `url(` + this.state.imageURL + `)` }}>
                    <div className="bannerHeight">
                        <Row className="bannerRow">
                            <Col lg={1}></Col>
                            <Col lg={6} className="bannerDiv" data-aos='fade-up'>
                                <h1 className="canelaBold bannerHeading">{section_title}</h1>
                                <p>{section_description}</p>

                            </Col>
                            <Col lg={5}></Col>
                        </Row>

                    </div>
                    <Row className="text-center">
                        <Col>
                            <div className="bannerBottom">
                                <p>SCROLL DOWN</p>
                                <div className="scroll-line"></div>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </Fragment>
        )
    }
}
export default NewsBanner