import React, { PureComponent, Fragment } from 'react';
import Header from '../common/header';
import Footer from '../common/footer';
import ThankyouBanner from './thank-you-banner'; 
class ThankYouPage extends PureComponent{
 
 
  render(){

      return( 
        <Fragment>
          <Header/>          
          <ThankyouBanner/>             
          <Footer />        
        </Fragment>
      )

  }
}

export default ThankYouPage