import React, { PureComponent, Fragment } from "react";
import "bootstrap-4-react";
import { lazy, Suspense } from 'react';



import { Helmet } from "react-helmet";
import axios from "axios";
import * as apiUrl from "../../apiUrl";

const Header = lazy(() => import("./../common/header"));
const Footer = lazy(() => import("./../common/footer"));

const HomeBanner = lazy(() => import("./../home-component/home-banner"));

const HomeEnquiryform = lazy(() =>
  import("./../home-component/home-enquiryform")
);
const HomeAbout = lazy(() => import("./../home-component/home-about"));
const HomeProducts = lazy(() => import("./../home-component/home-products"));


const HomeFeatured = lazy(() => import("./../home-component/home-featured"));
const HomeGallery = lazy(() => import("./../home-component/home-gallery"));
const HomeTestimonials = lazy(() => import("./../home-component/home-testimonials"));


const HomeBlog = lazy(() => import("./../home-component/home-blog"));
const HomeVisitOffice = lazy(() => import("./../home-component/home-visitoffice"));

class HomePage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      home_meta_title: "",
      home_meta_description: "",

      errorsxx: {},

      data: {},
    };

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    const self = this;
    axios.post(apiUrl.apiUrl + "/seometa/edit_seo_meta/1").then((res) => {
      self.setState({
        home_meta_title: res.data[0].home_meta_title,
        home_meta_description: res.data[0].home_meta_description,
      });
    });
  }

  render() {
    return (

    
      <Fragment>
          <Suspense fallback={<p>This is loading</p>}>
        <Header />
        <Helmet>
          <title>{this.state.home_meta_title}</title>
          <meta name="description" content={this.state.home_meta_description} />
          <meta
            name="keywords"
            content="houses for sale in dubai,apartments for sale in dubai,buy apartment in dubai,flat for sale in dubai,buy flat in dubai,rent to own apartments in dubai, luxury apartments for sale in dubai, flat prices in dubai"
          />

          <link rel="canonical" href="/home" />
        </Helmet>
        

        <HomeBanner />
        <HomeEnquiryform />
        <HomeAbout />
        <HomeProducts />
        <HomeFeatured />
        <HomeGallery />
        <HomeTestimonials />
        <HomeBlog />
        <HomeVisitOffice />
        <Footer />
      

        </Suspense>
      </Fragment>
    );
  }
}

export default HomePage;
