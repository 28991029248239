import React, { PureComponent, Fragment} from 'react';
import { Container, Col, Row } from 'bootstrap-4-react'; 
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';
 
class NewsInnerBanner extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

            selectedFiles: undefined,
            currentFile: undefined,
            section_title: "",
            section_description: "",

            errors: {},
            imageURL: "",
            data: {

            },





        };

       







    }


    async componentDidMount() {

        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("news-inner/");

        axios.post(apiUrl.apiUrl + '/news/edit_news_post/'+dataxx[1])
            .then(res =>

                this.setState({
                    section_title: res.data[0].section_title,
                    section_description2: res.data[0].section_description2,

                    imageURL: apiUrl.apiUrl + "/mvd/newspostsection/" + res.data[0].background_image,
                })

            );


        console.log(this.state);

    }


    render() {


        let section_title = this.state.section_title;

        let dataxx = section_title.split("\n");
    
        let section_description = this.state.section_description;
    
        let datayy = section_description.split("\n");

        return(
            <Fragment>

            <Container fluid className="bannerSec newsInnerBanner" style={{ backgroundImage: `url(` + this.state.imageURL + `)` }}>
                <div className="bannerHeight">
                    <Row className="bannerRow"> 
                        <Col lg={1}></Col>
                        <Col lg={10} className="bannerDiv" data-aos='fade-up'>   
                        <h1 className="canelaBold bannerHeading">{dataxx[0]}   {dataxx[1]}</h1>
                        <p>{datayy[0]} {datayy[1]}</p>
                         
                        
                        </Col> 
                        <Col lg={1}></Col> 
                    </Row> 
                  
                </div>
                    <Row className="text-center">
                        <Col>
                            <div className="bannerBottom">
                                <p>SCROLL DOWN</p>
                                <div className="scroll-line"></div>
                            </div>
                        </Col>
                    </Row>
             
            </Container>  

            </Fragment>
        )
    }
}
export default NewsInnerBanner