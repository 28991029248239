import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';


const Header = lazy(() => import("./../../common/header"));
const Footer = lazy(() => import("./../../common/footer"));
const InvestBanner = lazy(() => import("./../invest/invest-banner"));
const HomeEnquiryform = lazy(() => import("./../../home-component/home-enquiryform"));
const WhyInvest = lazy(() => import("./../invest/why-invest"));
const InvestList = lazy(() => import("./../invest/invest-list"));
const InvestTestimonials = lazy(() => import("../invest/invest-testimonials"));
const InvestGallery = lazy(() => import("../invest/invest-gallery"));
const HomeVisitOffice = lazy(() => import("./../../home-component/home-visitoffice"));


class InvestPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {


           
            investor_meta_title: "",
            investor_meta_description: "",

          

            errorsxx: {},

            data: {

            },

          
        };

        this.inputRef = React.createRef();


      

    }


    componentDidMount() {


        const self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
        .then(res => {

            self.setState({

                investor_meta_title: res.data[0].investor_meta_title,
                investor_meta_description: res.data[0].investor_meta_description,

            });




        });
    }



    render() {
        return (
            <Fragment>
                <Suspense fallback={<p></p>}>
                <Header />
                <Helmet>
                <title>{this.state.investor_meta_title}</title>
                    <meta name="description" content={this.state.investor_meta_description} />
                    <link rel="canonical" href='/invest' />
                </Helmet>
                <InvestBanner />
                <HomeEnquiryform />
                <WhyInvest />
                <InvestList />
                <InvestTestimonials />
                <InvestGallery />
                <HomeVisitOffice />
                <Footer />
                </Suspense>
            </Fragment>
        )
    }
}
export default InvestPage