import React, { PureComponent, Fragment } from 'react';
// import { Link } from 'react-router-dom';
import { Container, Col, Row, Button} from 'bootstrap-4-react';  
import axios from 'axios';
import * as apiUrl from '../../apiUrl';   
 
class HomeVisitOffice extends PureComponent {


    constructor(props) {
        super(props);
        this.state = {


            main_title: "",
            sub_title: "",
            button_text: "",
            button_link: "",
            errors: {},

        };

    }

    componentDidMount() {

        axios.post(apiUrl.apiUrl + '/home/edit_home_page_bottom_section/1')
            .then(res =>

                this.setState({
                    main_title: res.data[0].main_title,
                    sub_title: res.data[0].sub_title,
                    button_text: res.data[0].button_text,
                    button_link: res.data[0].button_link,

                })

            );


        

    }
  
    render() { 

        let button_link = this.state.button_link;
      
        return (
          <Fragment>  
            <Container fluid className="homeVisitSec"> 
                <Row>
                <Col lg={1}></Col>
                <Col lg={11}> 
                    <Row className="homeVisitDiv d-flex align-content-center flex-wrap justify-content-center">  
                        <Col md={10} lg={11}>
                          
                            <div className="homeVisitHead"> 
                                <h2 className="canelaBold bannerHead darkBlue" data-aos='fade-right'>{this.state.main_title}</h2> 
                               
                            </div>
                            <Row>
                                <Col md={6} className="text-left">
                                    <h6 className="canelaMedium secHeading" data-aos='fade-right'>{this.state.sub_title}</h6>
                                </Col>
                                <Col md={6} className="sm-text-center text-right" data-aos='fade-left'>
                                <a href={button_link}><Button className="darkBlueBtn">{this.state.button_text}</Button></a>
                                {/* <Link to={{ pathname: `/${this.state.button_link}` }}><Button className="darkBlueBtn">{this.state.button_text}</Button>  </Link> */}
                                </Col>
                            </Row>
                        </Col> 
                    </Row>     
                    
                </Col> 
                
                </Row>
            </Container>  

                      
          </Fragment>
        )
      }
}


export default HomeVisitOffice;

 