import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from "react-helmet";
import axios from 'axios';
import * as apiUrl from '../../../../apiUrl';


const Header = lazy(() => import("./../../../common/header"));
const Footer = lazy(() => import("./../../../common/footer"));
const CareerBanner = lazy(() => import("./../career-banner"));
// const HomeEnquiryform = lazy(() => import("./../../../home-component/home-enquiryform")); 
const CareerDescription = lazy(() => import("./../career-inner/career-description"));
const CareerForm = lazy(() => import("./../career-form"));
const HomeVisitOffice = lazy(() => import("./../../../home-component/home-visitoffice"));


class CareerInnerPage extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            job_title_description: "", job_title: "", facebook_link: "", linkedin_link: "",
            slug: "",

            formValues2: [{ id: "", slug: "", careers_inner_meta_title: "", careers_inner_meta_description: "", }],
            errors2: [{ slug: "", careers_inner_meta_title: "", careers_inner_meta_description: "", }],

        };






    }


    async componentDidMount() {

        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("career-inner/");


        this.setState({ slug: dataxx[1] });


        var self = this;
        axios.post(apiUrl.apiUrl + '/careerspagetopsection/edit_careers_job_desc/' + dataxx[1])
            .then(res => {



                this.setState({
                    job_title_description: res.data[0].job_title_description,
                    job_title: res.data[0].job_title,
                    facebook_link: res.data[0].facebook_link,
                    linkedin_link: res.data[0].linkedin_link,
                });





            });

          
            axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
            .then(res => {
    
                var children = res.data[0].child;
    
                var formValues2 = [...self.state.formValues2];
                var errors2 = [...self.state.errors2];


                formValues2.splice(0, 1);
                errors2.splice(0, 1);


                children.forEach(element => {

                    console.log(element.type);

                    var datayy = [];
                    var dataxx = [];

                    if (element.type == "careers" && element.slug==this.state.slug) {

                        dataxx.type = element.type;
                        dataxx.careers_inner_meta_title = element.meta_title;
                        dataxx.careers_inner_meta_description = element.meta_description;
                        dataxx.slug = element.slug;


                        datayy.type = "";
                        datayy.careers_inner_meta_title = "";
                        datayy.careers_inner_meta_description = "";
                        datayy.slug = "";

                        formValues2.push(dataxx);
                        errors2.push(datayy);
                    }
                   

                });

                
                self.setState({ errors2 });

                self.setState({ formValues2 });
    
    
            });

    }

    render() {
        return (
            <Fragment>
                <Suspense fallback={<p></p>}>
                <Helmet>
                    <title>{this.state.formValues2[0].careers_inner_meta_title}</title>
                    <meta name="description" content={this.state.formValues2[0].careers_inner_meta_description} />
                    <link rel="canonical" href={'/'+this.state.slug} />
                </Helmet>
                <Header />
                <CareerBanner />
                {/* <HomeEnquiryform/> */}
                <CareerDescription />
                <CareerForm />
                <HomeVisitOffice />
                <Footer />
                </Suspense>
            </Fragment>
        )
    }
}
export default CareerInnerPage