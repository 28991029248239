import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from "react-router-dom";
import axios from 'axios';
import * as apiUrl from '../../apiUrl';
import { Navigate } from 'react-router-dom';


class HomeEnquiryform extends PureComponent {


    constructor(props) {
        super(props);
        this.state = {


            errors: {},
            property_type_title: "",
            bedroom_title: "",
            price_title: "",
            communities_title: "",
            search_now_title: "",
            enquiry_now_title: "",

            property_lists: [],
            bedroom_lists: [],
            price_lists: [{ min: "", max: "", }],
            communities_lists: [],
            property_arr: [],
            bedroom_arr: [],

            communities_arr: [],
            price_arr: [],
            min: "",
            max: "",
        };

        this.searchNowFilter = this.searchNowFilter.bind(this);





        this.SetPropArray = this.SetPropArray.bind(this);
        this.SetBedroomArray = this.SetBedroomArray.bind(this);
        this.SetCommArray = this.SetCommArray.bind(this);
    }

    SetPropArray(value) {


        if (document.getElementById(value).checked == true) {

            let property_arr = this.state.property_arr;
            property_arr.push(value);

            this.setState({ property_arr: property_arr });


        }
        else if (document.getElementById(value).checked == false) {




            let property_arr = this.state.property_arr;

            const index = property_arr.indexOf(value);
            if (index > -1) { // only splice array when item is found
                property_arr.splice(index, 1); // 2nd parameter means remove one item only
            }



            this.setState({ property_arr: property_arr });
        }

        




    }

    SetBedroomArray(value) {


        if (document.getElementById(value).checked == true) {
            let bedroom_arr = this.state.bedroom_arr;
            bedroom_arr.push(value);

            this.setState({ bedroom_arr: bedroom_arr });

        }
        else if (document.getElementById(value).checked == false) {

            let bedroom_arr = this.state.bedroom_arr;

            const index = bedroom_arr.indexOf(value);
            if (index > -1) { // only splice array when item is found
                bedroom_arr.splice(index, 1); // 2nd parameter means remove one item only
            }

            this.setState({ bedroom_arr: bedroom_arr });
        }

        
    }


    SetCommArray(value,id) {


        if (document.getElementById(value).checked == true) {
            let communities_arr = this.state.communities_arr;
            communities_arr.push(id);

            this.setState({ communities_arr: communities_arr });

        }
        else if (document.getElementById(value).checked == false) {

            let communities_arr = this.state.communities_arr;

            const index = communities_arr.indexOf(id);
            if (index > -1) { // only splice array when item is found
                communities_arr.splice(index, 1); // 2nd parameter means remove one item only
            }

            this.setState({ communities_arr: communities_arr });
        }

        
    }



    searchNowFilter() {




        const self = this;

        let property_arr = self.state.property_arr;

       


        localStorage.setItem('property_arr', JSON.stringify(property_arr));

        let bedroom_arr = this.state.bedroom_arr;
        localStorage.setItem('bedroom_arr', JSON.stringify(bedroom_arr));

        let communities_arr = this.state.communities_arr;
        localStorage.setItem('communities_arr', JSON.stringify(communities_arr));

        let e = document.getElementById('price-min-range');
        var value = e.options[e.selectedIndex].value;
        localStorage.setItem('min', value);

        let e1 = document.getElementById('price-max-range');
        var value1 = e1.options[e1.selectedIndex].value;
        localStorage.setItem('max', value1);

       

        if(property_arr.length>0 || bedroom_arr.length>0 || communities_arr.length>0)
        {
        window.location.href = "/properties";
        }    

        

        

        
    }

    componentDidMount() {

        

        if (!localStorage.getItem('property_arr')) {
            localStorage.setItem('property_arr', JSON.stringify([]));
        }

        if (!localStorage.getItem('bedroom_arr')) {
            localStorage.setItem('bedroom_arr', JSON.stringify([]));
        }

        if (!localStorage.getItem('communities_arr')) {
            localStorage.setItem('communities_arr', JSON.stringify([]));
        }

        if (!localStorage.getItem('min')) {
            localStorage.setItem('min', JSON.stringify([]));
        }

        if (!localStorage.getItem('max')) {
            localStorage.setItem('max', JSON.stringify([]));
        }

        axios.post(apiUrl.apiUrl + '/home/edit_home_page_filter_section/1')
            .then(res =>

                this.setState({

                    property_type_title: res.data[0].property_type_title,
                    bedroom_title: res.data[0].bedroom_title,
                    price_title: res.data[0].price_title,
                    communities_title: res.data[0].communities_title,
                    search_now_title: res.data[0].search_now_title,
                    enquiry_now_title: res.data[0].enquiry_now_title,
                    property_arr: JSON.parse(localStorage.getItem('property_arr')),
                    bedroom_arr: JSON.parse(localStorage.getItem('bedroom_arr')),

                    communities_arr: JSON.parse(localStorage.getItem('communities_arr')),
                    min: localStorage.getItem('min'),
                    max: localStorage.getItem('max'),

                })

            );



        axios.post(apiUrl.apiUrl + '/filter/fetch_property_type_filter_section')
            .then(res => {

                this.setState({


                    property_lists: res.data,


                });


            }
            );


        axios.post(apiUrl.apiUrl + '/filter/fetch_bedroom_type_filter_section')
            .then(res =>

                this.setState({


                    bedroom_lists: res.data,


                })

            );




        axios.post(apiUrl.apiUrl + '/filter/fetch_price_type_filter_section')
            .then(res =>

                this.setState({


                    price_lists: res.data

                })

            );


        axios.post(apiUrl.apiUrl + '/filter/fetch_communities_type_filter_section')
            .then(res =>

                this.setState({


                    communities_lists: res.data,


                })

            );


    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row className="boxShadowBG">
                        <Col lg={1}></Col>
                        <Col lg={11} className="paddingZero">

                            <Accordion defaultActiveKey="0" flush className="homeEnquiryDiv">
                                <Row>
                                    <Col md={3} className="select-sec">
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header className="">
                                                <div>
                                                    <div className="search-fld">
                                                        <div className="search-label canelaMedium">{this.state.property_type_title}</div>
                                                        <div className="search-val">Any</div>
                                                    </div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="select-sec-options">
                                                <div className="accordionBodyInner">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6">
                                                            <ul>

                                                                {

                                                                    this.state.property_lists.map((item, i) => (

                                                                        <span key={Math.random()} >

                                                                            {i < 3 ?




                                                                                <li>
                                                                                    <input type="checkbox" id={item.name.toLowerCase()} name={item.name.toLowerCase()} value={item.name.toLowerCase()} onChange={() => { this.SetPropArray(item.name.toLowerCase()) }} defaultChecked={this.state.property_arr.includes(item.name.toLowerCase())} />
                                                                                    <label htmlFor={item.name.toLowerCase()}> {item.name}</label>

                                                                                </li>


                                                                                : ''

                                                                            }
                                                                        </span>




                                                                    ))

                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <ul>
                                                                {

                                                                    this.state.property_lists.map((item, i) => (

                                                                        <span key={Math.random()}>
                                                                            {i >= 3 ?


                                                                                <li >
                                                                                    <input type="checkbox" id={item.name.toLowerCase()} name={item.name.toLowerCase()} value={item.name.toLowerCase()} onChange={() => { this.SetPropArray(item.name.toLowerCase()) }} defaultChecked={this.state.property_arr.includes(item.name.toLowerCase())} />
                                                                                    <label htmlFor={item.name.toLowerCase()}> {item.name}</label>
                                                                                </li>
                                                                                : ''

                                                                            }

                                                                        </span>



                                                                    ))

                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row filter-bottom">
                                                        <div className="col-md-6 col-12 d-flex justify-content-start align-items-center pr-0">
                                                            <Link to='' className="clear-selection active-clear">Clear Selection</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Col>
                                    <Col md={3} className="select-sec">
                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header className="">
                                                <div>
                                                    <div className="search-fld">
                                                        <div className="search-label canelaMedium">{this.state.bedroom_title}</div>
                                                        <div className="search-val">Any</div>
                                                    </div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="select-sec-options">
                                                <div className="accordionBodyInner">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6">
                                                            <ul>
                                                                {

                                                                    this.state.bedroom_lists.map((item, i) => (

                                                                        <span key={Math.random()}>
                                                                            {i < 3 ?


                                                                                <li>
                                                                                    <input type="checkbox" id={item.name.toLowerCase()} name={item.name.toLowerCase()} value={item.name.toLowerCase()} onChange={() => { this.SetBedroomArray(item.name.toLowerCase()) }} defaultChecked={this.state.bedroom_arr.includes(item.name.toLowerCase())} />
                                                                                    <label htmlFor={item.name.toLowerCase()}> {item.name.toLowerCase()} {i == 0 ? 'Bedroom' : 'Bedrooms'} </label>
                                                                                </li>
                                                                                : ''

                                                                            }

                                                                        </span>



                                                                    ))

                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <ul>
                                                                {

                                                                    this.state.bedroom_lists.map((item, i) => (

                                                                        <span key={Math.random()}>
                                                                            {i >= 3 ?


                                                                                <li>
                                                                                    <input type="checkbox" id={item.name.toLowerCase()} name={item.name.toLowerCase()} value={item.name.toLowerCase()} onChange={() => { this.SetBedroomArray(item.name.toLowerCase()) }} defaultChecked={this.state.bedroom_arr.includes(item.name.toLowerCase())} />
                                                                                    <label htmlFor={item.name.toLowerCase()}> {item.name} Bedrooms </label>
                                                                                </li>
                                                                                : ''

                                                                            }

                                                                        </span>



                                                                    ))

                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row filter-bottom">
                                                        <div className="col-md-6 col-12 d-flex justify-content-start align-items-center pr-0">
                                                            <Link to='' className="clear-selection active-clear">Clear Selection</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Col>
                                    <Col md={3} className="select-sec">
                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header className="">
                                                <div>
                                                    <div className="search-fld">
                                                        <div className="search-label canelaMedium">{this.state.price_title}</div>
                                                        <div className="search-val">Any</div>
                                                    </div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="select-sec-options">
                                                <div className="accordionBodyInner">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6">
                                                            <div className="price-min-range select-field mb-3">
                                                                <label><span className="search-label">Min Price (AED)</span></label>
                                                                <select name="price-min-range" id="price-min-range">


                                                                    {

                                                                        this.state.price_lists.map((item, i) => (
                                                                            <option value={item.min} key={Math.random()} defaultValue={this.state.min == item.min}>{item.min}</option>
                                                                        ))

                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="price-max-range select-field mb-3">
                                                                <label><span className="search-label">Max Price (AED)</span></label>
                                                                <select name="price-max-range" id="price-max-range" >
                                                                    {

                                                                        this.state.price_lists.map((item, i) => (
                                                                            <option value={item.max} key={Math.random()} defaultValue={this.state.max == item.max}>{item.max}</option>
                                                                        ))

                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row filter-bottom">
                                                        <div className="col-md-6 col-12 d-flex justify-content-start align-items-center pr-0">
                                                            <Link to='' className="clear-selection active-clear">Clear Selection</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>

                                        </Accordion.Item>
                                    </Col>
                                    <Col md={3} className="select-sec communitiesDiv">
                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header className="">
                                                <div>
                                                    <div className="search-fld">
                                                        <div className="search-label canelaMedium">{this.state.communities_title}</div>
                                                        <div className="search-val">Any</div>
                                                    </div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body className="select-sec-options">
                                                <div className="accordionBodyInner">
                                                    <div className="row">
                                                        <div className="col-12 col-sm-6">
                                                            <ul>
                                                                {

                                                                    this.state.communities_lists.map((item, i) => (

                                                                        <span key={Math.random()}>
                                                                            {i < 3 ?


                                                                                <li>
                                                                                      <input type="checkbox" id={item.name.toLowerCase()} name={item.name.toLowerCase()} value={item.name.toLowerCase()} onChange={() => { this.SetCommArray(item.name.toLowerCase(),item.id) }} defaultChecked={this.state.communities_arr.includes(item.name.toLowerCase())} />
                                                                                    <label htmlFor={item.name.toLowerCase()}> {item.name}</label>
                                                                                </li>
                                                                                : ''

                                                                            }

                                                                        </span>



                                                                    ))

                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <ul>
                                                                {

                                                                    this.state.communities_lists.map((item, i) => (

                                                                        <span key={Math.random()}>
                                                                            {i >= 3 ?


                                                                                <li>
                                                                                    <input type="checkbox" id={item.name.toLowerCase()} name={item.name.toLowerCase()} value={item.name.toLowerCase()} onChange={() => { this.SetCommArray(item.name.toLowerCase(),item.id) }} defaultChecked={this.state.communities_arr.includes(item.name.toLowerCase())} />
                                                                                    <label htmlFor={item.name.toLowerCase()}> {item.name}</label>
                                                                                </li>
                                                                                : ''

                                                                            }

                                                                        </span>



                                                                    ))

                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="row filter-bottom">
                                                        <div className="col-md-6 col-12 d-flex justify-content-start align-items-center pr-0">
                                                            <Link to='' className="clear-selection active-clear">Clear Selection</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Col>
                                    <Col md={3} className="enquiryBtnDiv">
                                        <Button className="darkBlueBtn btn-block" onClick={this.searchNowFilter}>{this.state.search_now_title} </Button>
                                        <Link role="button" className="goldenColorBtn btn-block" to={{ pathname: '/contact' }}>{this.state.enquiry_now_title}</Link>
                                    </Col>
                                </Row>
                            </Accordion>
                        </Col>

                    </Row>
                </Container>
            </Fragment>
        )
    }
}


export default HomeEnquiryform