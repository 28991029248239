import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Button } from 'bootstrap-4-react';

import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

// swiper bundle styles
import 'swiper/swiper-bundle.min.css';

// swiper core styles
import 'swiper/swiper.min.css';


import axios from 'axios';
import * as apiUrl from '../../apiUrl';

SwiperCore.use([Autoplay, EffectFade, Navigation, Pagination]);
class LatestNews extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      formValues: [{ track_id: "", track_section_title: "", track_section_description: "", read_more_text: "", read_more_link: "", slider_image: [], imageURL: "", slug: "" }],

      formValues2: { section_title: "", section_description: "" }


    };

  }


  async componentDidMount() {

    
        axios.post(apiUrl.apiUrl + '/news/fetch_news_post')
            .then(res1 => {


                let formValues = [];

                res1.data.forEach((element) => {


                    element.track_id = element.id;
   
                    element.imageURL = apiUrl.apiUrl + "/mvd/newspostsection/" + element.background_image4;
                    element.section_description2 = element.section_description2.substring(0,100);
                    formValues.push(element);

                    console.log(element);

                });




                self.setState({ formValues: formValues });


            }

            );



    var self = this;
    axios.post(apiUrl.apiUrl + '/news/edit_news_page_second_section/1')
      .then(res => {



        //var formValues2 = self.state.formValues2;

        let formValues2 = [];
        formValues2.section_title = res.data[0].section_title;
        formValues2.section_description = res.data[0].section_description;





        self.setState({ formValues2 });



      







      });













  }



  render() {


    var formValues2 = this.state.formValues2;
    var formValues = this.state.formValues;

    console.log(formValues);

    return (
      <Fragment>
        <Container fluid className="floorPlanSec">
          <Row>
            <Col lg={1}></Col>
            <Col lg={11} className="">
              <Row className="d-flex justify-content-center">
                <Col md={8}>
                  <div className="careerSechead text-center">
                    <h5 className="canelaBold secHeading darkBlue text-uppercase" data-aos='fade-up'>{formValues2.section_title}</h5>
                    <p data-aos='fade-up'>{formValues2.section_description} </p>
                  </div>
                </Col>
              </Row>
              <div className="">
                {/* <Swiper
                  className="latestNewsSlider"
                  spaceBetween={50}
                  slidesPerView={2}
                  loop={false}
                  autoplay={false}
                  effect={'fade'}
                  navigation={true}
                  pagination={{ "dynamicBullets": true, "type": "fraction" }}
                > */}

                  {/* { */}

                    {/* formValues.map((item) => (
                       <SwiperSlide> */}

                        <div className="newsSliderDiv">

                          <Row>

                            {/* <Col md={8} className="newsImageDiv">
                              <div className="newsImage" data-aos='fade-right'>
                                <img
                                  src={item.imageURL}
                                  className="img-fluid"
                                  alt="slideimage1"
                                />
                              </div>
                            </Col> */}
                            {
                          formValues.map((item) => (                           
                            <Col md={4} className="newConDiv" data-aos='fade-up'>
                              <div className="newsContent">
                                <p className="goldenColor goldernSmallHeading">
                                  News
                                </p>
                                <h6 className="canelaMedium secHeading ">
                                  {item.section_title}       
                                  <span className='goldenColor'>{item.section_description2}</span>                          
                                </h6>

                                {/* <p>
                                {item.section_description2}
                                </p> */}
                                
                                <a href={item.slug} target="_blank" rel="noopener noreferrer">
                                    <Button className="btn goldenborderWhiteBtn">
                                      Read Article
                                  </Button>
                                </a>
                                
                                {/* <Link to={{ pathname: `/news-inner/${item.slug}` }}><Button className="btn goldenborderWhiteBtn px-5">
                                  Read Article
                                </Button></Link> */}
                              </div>
                            </Col>
                             ))
                             }

                          </Row>

                        </div>

                      {/* </SwiperSlide> */}


                   
                {/* </Swiper> */}
              </div>
            </Col>
          </Row>
        </Container>

      </Fragment>
    )
  }
}
export default LatestNews