import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import Header from './../common/header'; 
import Footer from './../common/footer';  
import { Link } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
class NotfoundPage extends PureComponent {
    render() { 
        return (
          <Fragment> 
               <Header />  
               <Helmet>
                    <title>Marquis Point</title>
                    <link rel="canonical" href='*' />
                </Helmet>
              <Container fluid className="not-found-bg bannerSec">
                  <div className="bannerHeight">
                      <Row className="bannerRow"> 
                          <Col lg={1}></Col>
                          <Col lg={6} className="bannerDiv"  data-aos='fade-up'>   
                          <h1 className="canelaBold bannerHead">Page Not Found</h1> 
                          <p>We can’t seem to find the page you’re looking for. We regret the inconvenience caused and will endeavour to fix it soon.</p>
                          <Link  to="/">
                          <Button className="goldenborderWhiteBtn">Back to Home</Button> 
                          </Link>
                          </Col> 
                          <Col lg={5}></Col> 
                      </Row> 
                  </div>
              </Container>  
              <Footer />
          </Fragment>
        )
      }
}


export default NotfoundPage