import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../apiUrl';

const Header = lazy(() => import("./../common/header"));
const Footer = lazy(() => import("./../common/footer"));
const PropertiesBanner = lazy(() => import("./properties-banner"));
const HomeEnquiryform = lazy(() => import("./../home-component/home-enquiryform"));
const PropertiesSale = lazy(() => import("./properties-for-sale"));
const LatestProducts = lazy(() => import("./latest-products"));
const HomeVisitOffice = lazy(() => import("./../home-component/home-visitoffice"));



class PropertiesPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {



            properties_meta_title: "",
            properties_meta_description: "",



            errorsxx: {},

            data: {

            },


        };

        this.inputRef = React.createRef();




    }


    componentDidMount() {


        const self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
            .then(res => {

                self.setState({

                    properties_meta_title: res.data[0].properties_meta_title,
                    properties_meta_description: res.data[0].properties_meta_description,

                });




            });
    }





    render() {
        return (
            <Fragment>
                <Suspense fallback={<p></p>}>
                <Header />
                <Helmet>
                    <title>{this.state.properties_meta_title}</title>
                    <meta name="description" content={this.state.properties_meta_description} />
                    <link rel="canonical" href='/properties' />
                </Helmet>
                <PropertiesBanner />
                <HomeEnquiryform />
                <PropertiesSale />
                <LatestProducts />
                <HomeVisitOffice />
                <Footer />
                </Suspense>
            </Fragment>
        )
    }
}
export default PropertiesPage