 
import React, { PureComponent, Fragment } from 'react';
import Header from './../../common/header'; 
import Footer from './../../common/footer'; 
import NewsInnerBanner from './news-inner-banner';
import HomeEnquiryform from './../../home-component/home-enquiryform';
import NewsDescription from './news-decriptions'; 
import HomeVisitOffice from './../../home-component/home-visitoffice';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';
class NewsInnerPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            selectedFiles: undefined,
            currentFile: undefined,
            section_title: "",
            section_description: "",
            section_background_image: "",
            section_title2: "",
            section_description2: "",

            errors: {},
            imageURL: "",


        };


    }


    async componentDidMount() {

        this.fetch_dataxx();
    }


    fetch_dataxx() {

        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("news-inner/");

        axios.post(apiUrl.apiUrl + '/news/edit_news_post/' + dataxx[1])
            .then(res =>

                this.setState({
                    section_title: res.data[0].section_title,
                    section_description: res.data[0].section_description,
                    post_id: res.data[0].id,
                    section_title2: res.data[0].section_title2,
                    section_description2: res.data[0].section_description2,
                    imageURL: apiUrl.apiUrl + "/mvd/newspostsection/" + res.data[0].section_background_image,
                })

            );


        console.log(this.state);

    }
    render(){
        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("news-inner/");
        return(
            <Fragment>
            <Header />  
            <Helmet>
                <title>{this.state.section_title}</title>
                <meta name="description" content={this.state.section_description} />

                <link rel="canonical" href={'/news-inner/' + dataxx[1]} />
            </Helmet>
               <NewsInnerBanner />
               <HomeEnquiryform />
               <NewsDescription /> 
               <HomeVisitOffice />
            <Footer />
            </Fragment>
        )
    }
}
 export default NewsInnerPage