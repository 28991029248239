import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';


class NewsDescription extends PureComponent {


  constructor(props) {
    super(props);
    this.state = {


      errors: {},
      section_title: "",
      section_description3: "",
      section_date: "",
      section_description2: "",
      section_image1: "",
      section_image2: "",
      section_image3: "",
      ImageURL1: "",
      ImageURL2: "",
      ImageURL3: "",


    };

  }

  async componentDidMount() {


    const windowUrl = window.location.href;

    let dataxx = windowUrl.split("news-inner/");



    axios.post(apiUrl.apiUrl + '/news/edit_news_post/'+dataxx[1])
      .then(res =>

        this.setState({

          section_title: res.data[0].section_title,
          section_description3: res.data[0].section_description3,
          section_description2: res.data[0].section_description2,
          section_date: res.data[0].section_date,
          ImageURL1: apiUrl.apiUrl + "/mvd/newspostsection/" + res.data[0].background_image1,
          ImageURL2: apiUrl.apiUrl + "/mvd/newspostsection/" + res.data[0].background_image2,
          ImageURL3: apiUrl.apiUrl + "/mvd/newspostsection/" + res.data[0].background_image3,

          section_image1: apiUrl.apiUrl + "/mvd/newspostsection/" + res.data[0].background_image1,
          section_image2: apiUrl.apiUrl + "/mvd/newspostsection/" + res.data[0].background_image2,
          section_image3: apiUrl.apiUrl + "/mvd/newspostsection/" + res.data[0].background_image3,
        })

      );


    console.log(this.state);

  }

  render() {


    let datazz = this.state.section_description3.split("\n");

    let dataxx = this.state.section_description2.split("\n");



    return (
      <Fragment>
        <Container fluid className="secSpacing">
          <Row>
            <Col lg={1}></Col>
            <Col lg={10}>
              <div className="newsInnerDiv">
                <Row>
                  <Col md={12}>
                    <p className="goldenColor goldernSmallHeading" data-aos='fade-up'>{this.state.section_date} </p>
                    <h6 className="canelaMedium secHeading" data-aos='fade-up'>{this.state.section_title}</h6>

                    <p data-aos='fade-up' >{datazz[0]}<br /><br />{datazz[1]}</p>

                    <img src={this.state.section_image1} data-aos='fade-right' className="img-fluid pb-4" alt="slideimage1" />

                    <p data-aos='fade-left'>{dataxx[0]}<br /> <br /> { dataxx[1]} </p>
                  </Col>
                </Row>


                <Row>
                  <Col md={6}>
                    <img src={this.state.section_image2} data-aos='fade-right' className="img-fluid pb-4" alt="slideimage2" />
                  </Col>
                  <Col md={6}>
                    <img src={this.state.section_image3} data-aos='fade-left' className="img-fluid" alt="slideimage3" />
                  </Col>
                </Row>

              </div>
            </Col>
            <Col lg={1}></Col>

          </Row>
        </Container>

      </Fragment>
    )
  }
}


export default NewsDescription