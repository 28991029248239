import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import 'bootstrap-4-react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../apiUrl';

const Header = lazy(() => import("../common/header"));
const Footer = lazy(() => import("../common/footer"));
const BlogBanner = lazy(() => import("../blog-component/blog-banner"));
const LatestBlog = lazy(() => import("../blog-component/latest-blogs"));
const BlogSwiper = lazy(() => import("../blog-component/blog-swiper"));
const HomeVisitOffice = lazy(() => import("../home-component/home-visitoffice"));



class PageBlog extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {



            blog_meta_title: "",
            blog_meta_description: "",



            errorsxx: {},

            data: {

            },


        };

        this.inputRef = React.createRef();




    }


    componentDidMount() {


        const self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
            .then(res => {

                self.setState({

                    blog_meta_title: res.data[0].blog_meta_title,
                    blog_meta_description: res.data[0].blog_meta_description,

                });




            });
    }

    render() {

        return (
            <Fragment>
                <Suspense fallback={<p></p>}>
                <Header></Header>
                <Helmet>
             
                    <title>{this.state.blog_meta_title}</title>
                    <meta name="description" content={this.state.blog_meta_description} />
                    <link rel="canonical" href='/blog' />
                </Helmet>
                <BlogBanner />
                <LatestBlog />
                <BlogSwiper />
                <HomeVisitOffice />
                <Footer></Footer>
                </Suspense>
            </Fragment>
        )

    }
}

export default PageBlog