import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import './../../styles/header.css';
// import Logo from './../../assets/img/2020 Holdings-01.svg';
import axios from 'axios';
import * as apiUrl from '../../apiUrl';

class Header extends PureComponent {

  constructor(props) {
    super(props);
    this.state = { addClass: false, addClass1: false, addClass2: false, data: [], parent_items: [], child_items: [],headerlogo:"",facebook_link:"",twitter_link:"",instagram_link:"",linkedin_link:"", youtube_link:"", }
  }

  fetchInventory = () => {

    var self = this;

    let parent_items = [];
    let child_items = [];

  



    axios.post(apiUrl.apiUrl + '/menu/fetch_header_menu_items')
      .then(res => {

        let child_itemsxx = [];

        res.data.map((item, i) => {

          if (item.parent_id == 0) {
            parent_items.push(item);
          }
          else {




            child_items.push(item);
          }



        });



        for (let ii in parent_items) {

          var id = parent_items[ii].id;

          parent_items[ii].has_child = 0;

          for (let jj in child_items) {
            var parent_id = child_items[jj].parent_id;

            if (parent_id == id) {
              parent_items[ii].has_child = 1;
            }

          }
        }

        this.setState({ parent_items: parent_items });
        this.setState({ child_items: child_items });

        console.log(this.state.child_items);
        console.log(this.state.parent_items);


      });



  }


  fetchLogo(){
    
    var self = this;
    axios.post(apiUrl.apiUrl + '/home/getSiteLogo')
    .then(res => {

      self.setState({ headerlogo: apiUrl.apiUrl+'/mvd/logo/'+res.data[0].headerlogo });
      

    });
  }

  fetchsocial(){
    var self = this;
    axios.post(apiUrl.apiUrl + '/footeraddr/edit_social_links/1')
    .then(res => {

      self.setState({ 
        facebook_link: res.data[0].facebook_link,
        twitter_link: res.data[0].twitter_link,
        linkedin_link: res.data[0].linkedin_link,
        instagram_link: res.data[0].instagram_link,
        youtube_link: res.data[0].youtube_link,
      
      });
      

    });

    
  }

  componentDidMount() {
    this.fetchInventory();
    this.fetchLogo();
    this.fetchsocial();
  }

  toggle() {
    this.setState({ addClass: !this.state.addClass });
  }

  toggle1() {
    this.setState({ addClass1: !this.state.addClass1 });
  }
  toggle2() {
    this.setState({ addClass2: !this.state.addClass2 });
  }

  render() {

    const windowUrl = window.location.href;

    let dataxx = windowUrl.split("/");

    let menuClass = ["main-menu-button main-menu-button--with-lang loaded"];
    let navBar = ["navbar hide-menu show-mobile-bg"];
    let openMenu = ["main-menu hide loaded"];
    let submenuClass = ["child sibling submenu-item hide"]
    let submenuClass1 = ["child sibling submenu-item hide"]
    if (this.state.addClass) {
      menuClass.push('show-menu');
      navBar.push('show-menu');
      openMenu.push('show');
    }
    if (this.state.addClass1) {
      submenuClass.push('active');
      submenuClass1.push('hide');
    }
    if (this.state.addClass2) {
      submenuClass1.push('active');
      submenuClass.push('hide');
    }

    return (
      <Fragment>
        <div className="navigation">
          <div id="navbar" className={navBar.join(' ')} onClick={this.toggle.bind(this)}>
            <Link to="/" className="site-logo loaded">
              <img src={this.state.headerlogo} className="img-fluid" alt="Logo" />
            </Link>
            <div to="/" className={menuClass.join(' ')} onClick={this.toggle.bind(this)}>
              <span className="title canelaMedium">
                <span className="open">Menu</span>
                <span className="close">Close</span>
              </span>
              <span className="bars">
                <span className="middle"></span>
              </span>
            </div>
            <div className="navbar-social-links">
              <a href={this.state.facebook_link} alt="Facebook" target="_blank" className="icon-mail"></a>
              <a href={this.state.instagram_link} alt="Facebook" target="_blank" className="icon-facebook"></a>
              {/* <a href={this.state.twitter_link} alt="Instagram" target="_blank" className="icon-instagram"></a> */}
              <a href={this.state.linkedin_link} alt="TripAdvisor" target="_blank" className="icon-trip-advisor"></a>
              <a href={this.state.youtube_link} alt="YouTube" target="_blank" className="icon-youtube"></a>

              
            </div>
          </div>

          <div id="main-menu" className={openMenu.join(' ')} >
            <div className="wrapper">
              <div className="main-menu-content">
                <Link to="/" className="site-logo main-menu-brand">
                  <img src={this.state.headerlogo} className="img-fluid" alt="Logo" />
                </Link>
                <nav>
                  <ul className="main-menu-list">
                    <div className="row">

                      <div className="col-md-6 dFlexDiv">



                        {

                          this.state.parent_items.map((item, i) => (




                            <>
                              {i < 3 ?



                                <>
                                  {item.has_child == 0 ?


                                    <> {item.menu_link == "/" + dataxx[3] ?

                                      <li className="child selected" onClick={this.toggle.bind(this)} key={'header_main_menu676_'+item.id}>
                                        <Link to={{ pathname:`${item.menu_link}`}} className=""><span className="title canelaMedium">{item.menu_text}</span></Link>
                                      </li>
                                      :
                                      <li className="child sibling" onClick={this.toggle.bind(this)} key={'header_main_menuyyyyyyy494_'+item.id}>
                                        <Link to={{ pathname:`${item.menu_link}`}} className=""><span className="title canelaMedium">{item.menu_text}</span></Link>
                                      </li>



                                    }</>
                                      :
                                      <li className={submenuClass.join(' ')} key={'header_main_menuppppppp99991_'+item.id}>
                                        <Link to="#" className="subdropdown-menu" onClick={this.toggle1.bind(this)}><span className="title canelaMedium">{item.menu_text}</span></Link>
                                        <ul className="sub-menu">

                                          {

                                            this.state.child_items.map((item_child, k) => (
                                              <>
                                                {item_child.parent_id == item.id ?
                                                  <li className="" onClick={this.toggle.bind(this)} key={'header_sub_menupppppppppp123_'+item_child.id}>
                                                    <Link to={{ pathname: item_child.menu_link.split('#')[0], hash: item_child.menu_link.includes('#') ? item_child.menu_link.split('#')[1] : '' }} className=""><span className="title canelaMedium">{item_child.menu_text}</span></Link>
                                                  </li>
                                                  : ''

                                                }
                                              </>

                                            ))

                                          }


                                        </ul>

                                      </li>


                                      }
                                    </>





                                    :
                                    ''


                                  }
                                </>


                             

                        ))




                      }


                            </div>







                            <div className="col-md-6 dFlexDiv">



                        {

                          this.state.parent_items.map((item, i) => (




                            <>
                              {i >= 3 ?



                                <>
                                  {item.has_child == 0 ?


                                    <> {item.menu_link == "/" + dataxx[3] ?

                                      <li className="child selected" onClick={this.toggle.bind(this)} key={'header_main_menu123_'+item.id}>
                                        <Link to={{ pathname: item.menu_link.split('#')[0], hash: item.menu_link.includes('#') ? item.menu_link.split('#')[1] : '' }} className=""><span className="title canelaMedium">{item.menu_text}</span></Link>

                                      </li>
                                      :
                                      <li className="child sibling" onClick={this.toggle.bind(this)} key={'header_main_menu1234567890_'+item.id}>
                                        <Link to={{ pathname: item.menu_link.split('#')[0], hash: item.menu_link.includes('#') ? item.menu_link.split('#')[1] : '' }} className=""><span className="title canelaMedium">{item.menu_text}</span></Link>

                                      </li>



                                    }</>
                                      :
                                      <li className={submenuClass1.join(' ')} key={'header_main_menuabcdefgh67890_'+item.id}>
                                        <Link to="#" className="subdropdown-menu" onClick={this.toggle2.bind(this)}><span className="title canelaMedium">{item.menu_text}</span></Link>
                                        <ul className="sub-menu">

                                          {

                                            this.state.child_items.map((item_child, k) => (
                                              <>
                                                {item_child.parent_id == item.id ?
                                                  <li className="" onClick={this.toggle.bind(this)} key={'header_sub_menuxxxxxxxxx456789_'+item_child.id}>
                                                 
                                                    <Link to={{ pathname: item_child.menu_link.split('#')[0], hash: item_child.menu_link.includes('#') ? item_child.menu_link.split('#')[1] : '' }} className=""><span className="title canelaMedium">{item_child.menu_text}</span></Link>

                                                  </li>
                                                  : ''

                                                }
                                              </>

                                            ))

                                          }


                                        </ul>

                                      </li>


                                      }
                                    </>





                                    :
                                    ''


                                  }
                                </>


                             

                        ))




                      }


                            </div>
                    </div>




                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

      </Fragment>
    )
  }
}

export default Header;