import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Col, Row, Button } from 'bootstrap-4-react';
import Form from 'react-bootstrap/Form';
import './../../styles/styles.scss';
import Handlogo from './../../assets/img/Man-01.svg';
import axios from 'axios';
import * as apiUrl from '../../apiUrl';

class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      addClass: false,
      addClass1: false,
      addClass2: false,
      parent_items: [],
      child_items: [],
      footerlogo: "",
      email_footer: "",
      errors: {},
      footer_address: "",
      phone_no: "",
      email_address: ""
    };
  }

  componentDidMount() {
    this.fetchInventory();
    this.fetchLogo();
    this.fetchFooterAddr();
  }

  fetchInventory = () => {

    var self = this;

    let parent_items = [];
    let child_items = [];





    axios.post(apiUrl.apiUrl + '/menu/fetch_footer_menu_items')
      .then(res => {

        let child_itemsxx = [];

        res.data.map((item, i) => {

          if (item.parent_id == 0) {
            parent_items.push(item);
          }
          else {




            child_items.push(item);
          }



        });



        for (let ii in parent_items) {

          var id = parent_items[ii].id;

          parent_items[ii].has_child = 0;

          for (let jj in child_items) {
            var parent_id = child_items[jj].parent_id;

            if (parent_id == id) {
              parent_items[ii].has_child = 1;
            }

          }
        }

        this.setState({ parent_items: parent_items });
        this.setState({ child_items: child_items });
        console.log("Footer:");
        console.log(this.state.child_items);



      });



  }

  fetchLogo = async () => {
    try {
      const res = await axios.post(`${apiUrl.apiUrl}/home/getSiteLogo`);
      this.setState({ footerlogo: `${apiUrl.apiUrl}/mvd/logo/${res.data[0].footerlogo}` });
    } catch (error) {
      console.error("Error fetching logo:", error);
    }
  };

  fetchFooterAddr = async () => {
    try {
      const res = await axios.post(`${apiUrl.apiUrl}/footeraddr/edit_footer_address/1`);
      this.setState({
        footer_address: res.data[0].footer_address,
        phone_no: res.data[0].phone_no,
        email_address: res.data[0].email_address
      });
    } catch (error) {
      console.error("Error fetching footer address:", error);
    }
  };

  handleValidation = () => {
    const { email_footer } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!email_footer) {
      formIsValid = false;
      errors["email_footer"] = "*Please Enter your Email";
    }

    this.setState({ errors });
    return formIsValid;
  };

  uploadFooter = async () => {
    if (this.handleValidation()) {
      const formData = new FormData();
      formData.append("email_footer", this.state.email_footer);

      try {
        await axios.post(`${apiUrl.apiUrl}/forms/save_footer_form`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        document.getElementById('myModalFooter').style.display = 'block';
        this.setState({ email_footer: "" });
      } catch (error) {
        console.error("Error uploading footer:", error);
      }
    }
  };

  closeModalDialog = () => {
    document.getElementById('myModalFooter').style.display = 'none';
  };

  onChangeEmailFooter = event => {
    this.setState({ email_footer: event.target.value, errors: { ...this.state.errors, email_footer: "" } });
  };

  render() {
    const { parent_items, child_items, footerlogo, footer_address, phone_no, email_address, email_footer, errors } = this.state;

    return (
      <Fragment>
        <Container fluid className="darkBlueBG footerDiv">
          <Row>
            <Col md={1} lg={1}></Col>
            <Col md={12} lg={11}>
              <div className="centerFootdiv">
                <Row className="footerTopdiv justify-content-xl-around">
                  <Col lg={1} md={1} className="paddingZero">
                    <Link to='/home'>
                      <img src={footerlogo} className="img-fluid" alt="footer logo" />
                    </Link>
                  </Col>
                  <Col lg={5} md={5} className="paddingZero">
                    <div className="addressDiv">
                      <p>{footer_address}</p>
                      <a href={`tel:${phone_no}`}>{phone_no}</a>
                      <a href={`mailto:${email_address}`}>{email_address}</a>
                    </div>
                  </Col>
                  <Col lg={4} md={5} className="paddingZero">
                    <Form>
                      <Form.Group className="mb-3 subscribeForm">
                        <Form.Control className="subscribeText" type="text" id="email_footer" value={email_footer} onChange={this.onChangeEmailFooter} placeholder="ENTER EMAIL..." />
                        <span className="form-error footer-form-error">{errors["email_footer"]}</span>
                        <Button className="subscribeButton canelaMedium" type="button" onClick={this.uploadFooter}>SUBSCRIBE</Button>
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                <Row className="borderTop footerLinks justify-content-xl-around">
                  {parent_items.map((item) => {
                    // Filter child items for the current parent item
                    const filteredChildItems = child_items.filter(child => child.parent_id == item.id);

                    return (
                      <Col lg={3} xl={2} md={3} key={`footer_main_menu_${item.id}`}>
                        <h4 className="canelaBold heading">{item.menu_text}</h4>
                        {filteredChildItems.length > 0 && (
                          <div>
                            {filteredChildItems.map(child => (
                              <p key={`footer_sub_menu_${child.id}`}>
                                <Link to={{ pathname: child.menu_link.split('#')[0], hash: child.menu_link.includes('#') ? `#${child.menu_link.split('#')[1]}` : '' }}>
                                  {child.menu_text}
                                </Link>
                              </p>
                            ))}
                          </div>
                        )}
                      </Col>
                    );
                  })}

                </Row>
              </div>
            </Col>
            <Col md={1} lg={1}></Col>
          </Row>
          <Row className="copyRightDiv">
            <Col>
              <p>Website By <a href="https://www.tablonoir.com" target='_blank' rel="noopener noreferrer">Tablo Noir</a>. © Marquis Point. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
        <div className="becomeAgent">
          <Link to='/become-an-agent'><Button className="goldenColorBtn btn-block">Become an Agent</Button></Link>
        </div>
        <Link to='/become-an-agent' className="agentIcon">
          <img src={Handlogo} alt="handlogo" className="img-fluid" />
        </Link>
        <Row>
          <div className="modal form-modal" tabIndex="-1" role="dialog" id="myModalFooter">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" onClick={this.closeModalDialog} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h2 className="canelaMedium secHeading">Thank you, your response was sent</h2>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn" data-dismiss="modal" onClick={this.closeModalDialog}>Close</button>
                </div>
              </div>
            </div>
            <div className="modal-backdrop"></div>
          </div>
        </Row>
      </Fragment>
    );
  }
}

export default Footer;
