import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';


const Header = lazy(() => import("./../../common/header"));
const Footer = lazy(() => import("./../../common/footer"));
const CommuntiesInnerBanner = lazy(() => import("./communties-inner-banner"));
const HomeEnquiryform = lazy(() => import("./../../home-component/home-enquiryform"));
const CommuntiesProperties = lazy(() => import("./communties-properties"));
const AmenitiesServices = lazy(() => import("./amenities-services"));
const CommuntiesProducts = lazy(() => import("./communties-products"));
const NearByCommunties = lazy(() => import("./nearby-communties"));
const HomeVisitOffice = lazy(() => import("./../../home-component/home-visitoffice"));


class CommuntiesInnerPage extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {

            selectedFiles: undefined,
            currentFile: undefined,
            section_title: "",
            section_description: "",
            section_background_image: "",
            section_title2: "",
            section_description2: "",

            errors: {},
            imageURL: "",


            formValues3: [{ id: "", slug: "", communities_inner_meta_title: "", communities_inner_meta_description: "", }],
            errors3: [{ slug: "", communities_inner_meta_title: "", communities_inner_meta_description: "", }],


        };


    }


    async componentDidMount() {

        this.fetch_dataxx();

        const windowUrl = window.location.href;

        let dataxxyyzz = windowUrl.split("communties-inner/");

        var self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
            .then(res => {

                var children = res.data[0].child;

                var formValues3 = [...self.state.formValues3];



                formValues3.splice(0, 1);



                children.forEach(element => {

                    console.log(element.type);


                    var dataxx = [];

                    if (element.type == "communities" && element.slug == dataxxyyzz[1]) {

                        dataxx.type = element.type;
                        dataxx.communities_inner_meta_title = element.meta_title;
                        dataxx.communities_inner_meta_description = element.meta_description;
                        dataxx.slug = element.slug;




                        formValues3.push(dataxx);

                    }


                });




                self.setState({ formValues3 });


            });
    }


    fetch_dataxx() {

        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("communties-inner/");

        axios.post(apiUrl.apiUrl + '/communitiesinner/edit_communitiesinner_post/' + dataxx[1])
            .then(res =>

                this.setState({
                    section_title: res.data[0].section_title,
                    section_description: res.data[0].section_description,
                    post_id: res.data[0].id,
                    section_title2: res.data[0].section_title2,
                    section_description2: res.data[0].section_description2,
                    imageURL: apiUrl.apiUrl + "/mvd/communitiesinnerpostsection/" + res.data[0].section_background_image,
                })

            );


        console.log(this.state);

    }
    render() {
        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("communties-inner/");
        return (
            <Fragment>
                <Suspense fallback={<p></p>}>
                <Header />
                <Helmet>


                    <title>{this.state.formValues3[0].communities_inner_meta_title}</title>
                    <meta name="description" content={this.state.formValues3[0].communities_inner_meta_description} />
                    <meta name="keywords" content="Premium Apartments for sale in dubai, Luxury Apartments in Arjan, 3 BHK luxurious apartments, 2 BHK apartments  in Arjan, Apartments for sale, Apartments in Arjan" />
                    <link rel="canonical" href={'/communties-inner/' + dataxx[1]} />
                </Helmet>
                <CommuntiesInnerBanner />
                <HomeEnquiryform />
                <CommuntiesProperties />
                <AmenitiesServices />
                <CommuntiesProducts />
                {/*  <NearByCommunties /> */}
                <HomeVisitOffice />
                <Footer />
                </Suspense>
            </Fragment>
        )
    }
}
export default CommuntiesInnerPage