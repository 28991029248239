import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';
import { useParams } from "react-router-dom";


const Header = lazy(() => import("./../../common/header"));
const Footer = lazy(() => import("./../../common/footer"));
const PropertiesInnerBanner = lazy(() => import("./properties-inner-banner"));
const HomeEnquiryform = lazy(() => import("./../../home-component/home-enquiryform"));
const PropertiesConstruction = lazy(() => import("./construction"));
const PropertiesDescription = lazy(() => import("./properties-descriptions"));
// const PropertiesAmenities = lazy(() => import("./properties-amenities-swiper"));
const PropertiesAmentiesSwiper = lazy(() => import("./property-amenities-swiper"));
const FloorPlan = lazy(() => import("./floor-plan"));
const InteriorExterior = lazy(() => import("./interior-exterior"));
const PropertiesLocationSwiper = lazy(() => import("./properties-location-swiper"));
const PropertiesVideo = lazy(() => import("./properties-video"));
const HomeVisitOffice = lazy(() => import("./../../home-component/home-visitoffice"));



function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}


class PropertiesInnerPage extends PureComponent {
    constructor(props) {
        super(props);



        this.state = {

            selectedFiles: undefined,
            currentFile: undefined,
            section_title: "",
            section_description: "",
            section_background_image: "",
            section_title2: "",
            section_description2: "",

            errors: {},
            imageURL: "",

            formValues5: [{ id: "", slug: "", properties_inner_meta_title: "", properties_inner_meta_description: "", }],
            errors5: [{ slug: "", properties_inner_meta_title: "", properties_inner_meta_description: "", }],
            slug: ""

        };


    }

    componentDidUpdate(prevProps) {
        this.fetch_dataxx();

        let { id } = this.props.params;



        if (this.props.params.id !== prevProps.params.id) {

            this.setState({ slug: id });



            var self = this;
            axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
                .then(res => {

                    var children = res.data[0].child;

                    var formValues5 = [...self.state.formValues5];



                    formValues5.splice(0, 1);



                    children.forEach(element => {

                        console.log(element.type);


                        var dataxx = [];

                        if (element.type == "properties" && element.slug == id) {

                            dataxx.type = element.type;
                            dataxx.properties_inner_meta_title = element.meta_title;
                            dataxx.properties_inner_meta_description = element.meta_description;
                            dataxx.slug = element.slug;




                            formValues5.push(dataxx);

                        }


                    });




                    self.setState({ formValues5 });


                });

        }

    }



    async componentDidMount() {





        this.fetch_dataxx();

        let { id } = this.props.params;

        this.setState({ slug: id });



        var self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
            .then(res => {

                var children = res.data[0].child;

                var formValues5 = [...self.state.formValues5];



                formValues5.splice(0, 1);



                children.forEach(element => {

                    console.log(element.type);


                    var dataxx = [];

                    if (element.type == "properties" && element.slug == id) {

                        dataxx.type = element.type;
                        dataxx.properties_inner_meta_title = element.meta_title;
                        dataxx.properties_inner_meta_description = element.meta_description;
                        dataxx.slug = element.slug;




                        formValues5.push(dataxx);

                    }


                });




                self.setState({ formValues5 });


            });
    }


    fetch_dataxx() {




        let { id } = this.props.params;



        axios.post(apiUrl.apiUrl + '/propertiesinnerpage/edit_propertiesinner_post/' + id)
            .then(res =>

                this.setState({
                    section_title: res.data[0].section_title,
                    section_description: res.data[0].section_description,
                    post_id: res.data[0].id,
                    section_title2: res.data[0].section_title2,
                    section_description2: res.data[0].section_description2,
                    imageURL: apiUrl.apiUrl + "/mvd/propertiesinnerpagepostsection/" + res.data[0].section_background_image,
                })

            );




    }




    render() {
        const windowUrl = window.location.href;

        let dataxx = windowUrl.split("properties-inner/");

        console.log(dataxx[1]);

        let { id } = this.props.params;
        return (
            <Fragment>
                <Suspense fallback={<p></p>}>
                <Header />
                <Helmet>
                    <title>{this.state.formValues5[0].properties_inner_meta_title}</title>
                    <meta name="description" content={this.state.formValues5[0].properties_inner_meta_description} />
                    {
                        dataxx[1] == '2020-marquis' ?

                            <meta name="keywords" content="2 bedroom apartments,1 bedroom apartments near me, apartments for sale, 1 bedroom apartments, lats to rent near me, 3 bedroom apartments for rent, 3 bedroom for rent." />

                            : dataxx[1] == 'marquis-signature' ?
                                <meta name="keywords" content="Luxury apartments, buy apartment in dubai,flat for sale in dubai, apartments for rent near me,best apartments near me" />

                                : ''
                    }
                    <link rel="canonical" href={'/properties-inner/' + dataxx[1]} />
                </Helmet>
                <PropertiesInnerBanner />
                <HomeEnquiryform />
                {(() => {
                    if (id == "marquis-signature") {
                        return <PropertiesConstruction />;
                    } else {
                        return '';
                    }
                })()}








                <PropertiesDescription />
                <PropertiesAmentiesSwiper />
                <FloorPlan />
                <InteriorExterior />
                <PropertiesLocationSwiper />
                <PropertiesVideo />
                <HomeVisitOffice />
                <Footer />
                </Suspense>
            </Fragment>
        )
    }
}
//export default PropertiesInnerPage


export default withParams(PropertiesInnerPage);