import React, { PureComponent, Fragment } from 'react';
import { lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import * as apiUrl from '../../../apiUrl';

const Header = lazy(() => import("./../../common/header"));
const Footer = lazy(() => import("./../../common/footer"));
const OurteamBanner = lazy(() => import("./../../about-component/our-team/ourteam-banner"));
const HomeEnquiryform = lazy(() => import("./../../home-component/home-enquiryform"));
const CeoProfile = lazy(() => import("./../../about-component/our-team/ceo-profile"));
const Ourmanagement = lazy(() => import("./../../about-component/our-team/our-management"));
const HomeVisitOffice = lazy(() => import("./../../home-component/home-visitoffice"));


class OurteamPage extends PureComponent {


    constructor(props) {
        super(props);
        this.state = {



            ourteam_meta_title: "",
            ourteam_meta_description: "",



            errorsxx: {},

            data: {

            },


        };

        this.inputRef = React.createRef();




    }


    componentDidMount() {


        const self = this;
        axios.post(apiUrl.apiUrl + '/seometa/edit_seo_meta/1')
            .then(res => {

                self.setState({

                    ourteam_meta_title: res.data[0].ourteam_meta_title,
                    ourteam_meta_description: res.data[0].ourteam_meta_description,

                });




            });
    }


    render() {
        return (
            <Fragment>
                <Suspense fallback={<p></p>}>
                <Header />
                <Helmet>
                    <title>{this.state.ourteam_meta_title}</title>
                    <meta name="description" content={this.state.ourteam_meta_description} />
                    <link rel="canonical" href='/ourteam' />
                </Helmet>
                <OurteamBanner />
                <HomeEnquiryform />
                <CeoProfile />
                <Ourmanagement />
                <HomeVisitOffice />
                <Footer />
                </Suspense>
            </Fragment>
        )
    }
}
export default OurteamPage